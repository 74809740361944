import { Controller } from "stimulus";

let source, actualPlayer, youTubeAPIReady, youTubeAPICheckingInterval;

export default class extends Controller {
  static targets = ["wrapper", "viewer"];

  connect() {
    const that = this;

    that.source = that.viewerTarget.getAttribute("data-source");

    if (that.source === "youtube") {
      that.checkYouTubeAPI();

      if (!that.youTubeAPIReady) {
        that.youTubeAPICheckingInterval = window.setInterval(function() {
          that.checkYouTubeAPI();
        }, 200);
      }
    }
    else if (that.source === "vimeo") {
      this.actualPlayer = new Vimeo.Player(that.viewerTarget.querySelector(".player"));

      // that.actualPlayer.on("pause", function() {
      //   that.showMask();
      // });
    }
    else {
      that.actualPlayer = that.viewerTarget.querySelector(".player");
    }
  }

  disconnect() {
    window.clearYouTubePlayers();
  }

  play() {
    this.pauseAll();

    if (this.source === "youtube") {
      this.actualPlayer.playVideo();
      window.setCurrentYouTubePlayer(this.actualPlayer);
    }
    else if (this.source === "vimeo") {
      this.actualPlayer.play();
    }
    else {
      this.actualPlayer.play();
    }

    this.hideMask();
  }

  pause() {
    this.showMask();

    if (this.source === "youtube") {
      this.actualPlayer.pauseVideo();
      window.setCurrentYouTubePlayer(null);
    }
    else if (this.source === "vimeo") {
      this.actualPlayer.pause();
    }
    else {
      this.actualPlayer.pause();
    }
  }

  pauseAll() {
    let players = document.querySelectorAll(".player");

    for (let player of players) {
      if (player.classList.contains("vimeo")) {
        player.contentWindow.postMessage('{"method":"pause"}', "*");
      }
      else if (player.classList.contains("local")) {
        player.pause();
      }
    }

    players = window.getYouTubePlayers();

    for (let player of players) {
      player.pauseVideo();
    }
  }

  hideMask() {
    if (this.viewerTarget.classList.contains("-modal")) {
      document.body.classList.add("lock-scroll");
    }

    this.viewerTarget.classList.add("show");
  }

  showMask() {
    if (this.viewerTarget.classList.contains("-modal")) {
      document.body.classList.remove("lock-scroll");
    }

    this.viewerTarget.classList.remove("show");
  }

  checkYouTubeAPI() {
    const that = this;

    console.log("TEST");

    window.setTimeout(function() {
      that.youTubeAPIReady = window.isYouTubeAPIReady();

      if (that.youTubeAPIReady) {
        that.initYouTubePlayer();

        if (that.youTubeAPICheckingInterval) {
          clearInterval(that.youTubeAPICheckingInterval);
        }
      }
      else {
        window.loadYouTubeAPI();
      }
    }, 10);
  }

  initYouTubePlayer() {
    const that = this;
    const player = that.viewerTarget.querySelector(".player");
    const videoID = that.viewerTarget.getAttribute("data-video-id");

   try {
      that.actualPlayer = new YT.Player(player, {
        videoId: videoID,
        playerVars: {
          modestbranding: 1,
          loop: 0,
          showInfo: 0,
          rel: 0,
          color: "white",
          showinfo: 0,
          controls: 1,
          suggestedQuality: "large",
        },
        events: {
          "onStateChange": function(event) {
            if (event.data == YT.PlayerState.PAUSED) {
              that.showMask();
            }
          }
        }
      });

      window.regYouTubePlayer(that.actualPlayer);
    }
    catch(e) {
      that.initYouTubePlayer();
    }
  }
}
