import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "analyticsPermission"];

  connect() {
    // console.log("connect::cookies-modal-controller");

    if (this.hasAnalyticsPermission) this.checkAnalyticsPermission();

    this.cookieName = "robles_cookies_with_analytics_accepted_managed";

    if (this.readCookie(this.cookieName) == undefined) {
      this.modalTarget.classList.add("present");
    } else {
      this.modalTarget.remove();
    }
  }

  acceptCookies() {
    if (this.hasAnalyticsPermission) {
      let gaPermitted = this.analyticsPermissionTarget.checked;

      this.createCookie("robles_google_analytics_permission", gaPermitted, 365);
      this.checkAnalyticsPermission();
    }

    this.createCookie(this.cookieName, "displayed", 365);
    this.modalTarget.classList.add("hide");
  }

  createCookie(name, value, days) {
    let config = "";

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      config = `; expires=${date.toGMTString()}; SameSite=Strict`;
    }

    document.cookie = `${name}=${value}${config}; path=/`;
  };

  readCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];

      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }

    return undefined;
  };

  checkAnalyticsPermission() {
    let permitted = this.readCookie("robles_google_analytics_permission");

    if (permitted == undefined) permitted = false;

    window[`ga-disable-${document.body.getAttribute("data-google-analytics-key")}`] = !permitted;

    console.log(permitted);
  }
}
