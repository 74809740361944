import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import LocomotiveScroll from "locomotive-scroll";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const application = Application.start();
const context = require.context("../controllers/front", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

// JS
import "../js/common.js";
import "../js/youtube_api_loader.js";

// Styles
import "../styles/front/locomotive_scroll.css";
import "../styles/front/shared/langs_select.scss";
import "../styles/front/shared/fade_block.scss";
import "../styles/front/shared/menu.scss";
import "../styles/front/shared/mega_menu.scss";
import "../styles/front/shared/generic_card.scss";
import "../styles/front/shared/contact_form.scss";
import "../styles/front/shared/cookies_modal.scss";
import "../styles/front/shared/project_card.scss";
import "../styles/front/shared/project_preview.scss";
import "../styles/front/shared/publication_card.scss";
import "../styles/front/shared/filters_menu.scss";
import "../styles/front/shared/video_viewer.scss";
import "../styles/front/shared/quote.scss";
import "../styles/front/shared/entities_carrousel.scss";
import "../styles/front/shared/brands_carrousel.scss";
import "../styles/front/shared/modal.scss";
import "../styles/front/shared/contact_us_banner.scss";
import "../styles/front/shared/gallery.scss";
import "../styles/front/shared/image_viewer.scss";
import "../styles/front/shared/clients_carrousel.scss";
import "../styles/front/shared/timeline.scss";
import "../styles/front/shared/member_card.scss";
import "../styles/front/shared/footer.scss";
import "../styles/front/front.scss";
import "../styles/front/home.scss";
import "../styles/front/materials.scss";
import "../styles/front/brand.scss";
import "../styles/front/projects.scss";
import "../styles/front/services.scss";
import "../styles/front/about_us.scss";
import "../styles/front/publications.scss";
import "../styles/front/contact.scss";
import "../styles/front/legal_terms.scss";

let locomotiveScroll;
let menuVisibilityTimeout = null;
let prevWindowWidth = window.innerWidth;

document.addEventListener("turbo:load", function () {
  // console.log(`Ctrl 1 -> ${new Date().getMilliseconds()} ms`);

  // new imagesLoaded(document.body).on("progress", function(instance, image) {
  //   var result = image.isLoaded ? "loaded" : "broken";
  //   console.log(`image is ${result} for ${image.img.src} -> ${new Date().getMilliseconds()} ms`);
  // });

  // console.log(`Ctrl 2 -> ${new Date().getMilliseconds()} ms`);

  new imagesLoaded(document.body, { background: true }, function() {
    // console.log(`Ctrl 3 -> ${new Date().getMilliseconds()} ms`);
    window.setTimeout(function() {
      document.body.classList.remove("loading");
    }, 90);

    window.setTimeout(function() {
      // console.log(`Ctrl 4 -> ${new Date().getMilliseconds()} ms`);

      if (document.body.id === "staticsAboutUs") {
        gsap.registerPlugin(ScrollTrigger);
      }

      // const megaMenu = document.getElementById("megaMenu");

      const filtersMenu = document.getElementById("filtersMenu");

      locomotiveScroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        smoothMobile: false,
        getSpeed: false,
        getDirection: true,
        firefoxMultiplier: 100,
        lerp: 0.75,
        inertia: 0.8,
        touchMultiplier: 10,
        scrollFromAnywhere: false,
        resetNativeScroll: true,
        reloadOnContextChange: true,
        tablet: {
          smooth: false,
          getDirection: true,
        },
        smartphone: {
          smooth: false,
          getDirection: true,
        }
      });

      locomotiveScroll.on("call", (func, dir, obj) => {
        if (func == "setVisible") {
          obj.el.classList.add("visible");
        }
      });

      locomotiveScroll.on("scroll", (obj) => {
        if (document.body.id === "staticsAboutUs") {
          ScrollTrigger.update();
        }

        if (filtersMenu) {
          let filtersReference = document.querySelector(filtersMenu.getAttribute("data-reference")).clientHeight * 1.2;

          if (obj.scroll.y <= filtersReference) {
            filtersMenu.classList.add("lock");
          }
          else {
            filtersMenu.classList.remove("lock");
          }
        }

        if (window.innerWidth <= 1024) {
          // if (obj.scroll.y <= window.innerHeight) {
          //   megaMenu.classList.add("lock");
          // }
          // else {
          //   megaMenu.classList.remove("lock");
          // }

          // megaMenu.classList.add("visible");

          // clearTimeout(menuVisibilityTimeout);

          // menuVisibilityTimeout = window.setTimeout(() => {
          //   megaMenu.classList.remove("visible");
          // }, 20000);
        }

        let inners = document.querySelectorAll(".menu, .modal, .video-viewer--viewer.-as-modal");

        for (let inner of inners) {
          inner.onmouseover = function() {
            locomotiveScroll.stop();
          };

          inner.onmouseout = function() {
            locomotiveScroll.start();
          };
        }
      });

      if (document.body.id === "staticsAboutUs") {
        ScrollTrigger.scrollerProxy("body", {
          scrollTop(value) {
            return arguments.length ? locomotiveScroll.scrollTo(value, 0, 0) : locomotiveScroll.scroll.instance.scroll.y;
          },
          getBoundingClientRect() {
            return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
          },
          pinType: document.querySelector("body").style.transform ? "transform" : "fixed"
        });

        const timelineContainer = document.querySelector(".timeline--container");
        const timelineCardWidth = timelineContainer.querySelector(".timeline--card--wrapper").offsetWidth;
        const visibleCards = (window.innerWidth - timelineContainer.style.paddingLeft) / timelineCardWidth;
        let cardsOffset = 2;
        let totalCards = 13 + cardsOffset;
        const cardsCount = window.innerWidth <= 675 ? totalCards - 2 : totalCards;
        const Xlimit = ((timelineCardWidth * cardsCount) - timelineContainer.style.paddingRight * .9) - (visibleCards * timelineCardWidth);

        gsap.timeline(".timeline--container", {
          scrollTrigger: {
            trigger: "#timelineSection",
            scrub: true,
            pin: true,
            start: "top top",
            end: `+=${Xlimit}px`
          },
          x: 0
        }).to(".timeline--container", {
          scrollTrigger: {
            trigger: "#timelineSection",
            scrub: true,
            pin: true,
            start: "top top",
            end: `+=${Xlimit}px`
          },
          x: `-${Xlimit}px`
        });

        ScrollTrigger.addEventListener("refresh", () => locomotiveScroll.update());

        ScrollTrigger.refresh();
      }

      locomotiveScroll.update();


      window.addEventListener("resize", debounce(function() {
        if (window.innerWidth > 1025 && prevWindowWidth - window.innerWidth > 200 ||  window.innerWidth - prevWindowWidth > 200) {
          prevWindowWidth = window.innerWidth;
          location.reload();
        }
      }), 100);

      // console.log(`Ctrl 5 -> ${new Date().getMilliseconds()} ms`);
    }, 80);

    // console.log(`Ctrl 6 -> ${new Date().getMilliseconds()} ms`);
  });
});
