let youTubePlayerReady = false;
let youTubePlayerIntents = 0;
let youTubeTaken = false;
let youTubePlayers = [];
let currentPlayer = null;

window.loadYouTubeAPI = function() {
  if (document.querySelector(".video-viewer--viewer.youtube")) {
    if (!youTubePlayerReady && !youTubeTaken) {
      youTubeTaken = true;

      try {
        let tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";

        let firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        youTubePlayerReady = true;
      }
      catch {
        ++youTubePlayerIntents;

        console.log("error loading YouTube player - intent number " + this.youTubePlayerIntents);

        if (youTubePlayerIntents < 4) {
          youTubeTaken = false;
          loadYouTubeAPI();
        }
        else {
          return false;
        }
      }
    }

    return true;
  }
}

window.isYouTubeAPIReady = function() {
  return youTubePlayerReady;
}

window.regYouTubePlayer = function(player) {
  youTubePlayers.push(player);
}

window.getYouTubePlayers = function() {
  return youTubePlayers;
}

window.clearYouTubePlayers = function() {
  youTubePlayers = [];
}

window.setCurrentYouTubePlayer = function(player) {
  currentPlayer = player;
}

window.getCurrentYouTubePlayer = function() {
  return currentPlayer;
}
