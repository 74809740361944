import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.key = document.body.getAttribute("data-google-analytics-key");

    if (!window[`ga-disable-${this.key}`]) {
      // console.log("connect::analytics-controller");

      this.dataLayer = window.dataLayer || [];

      this.gtag("js", new Date());

      this.gtag("config", this.key);

      let name = window.location.href.replace(window.location.protocol + "//" + window.location.hostname, "").replace(":5000", "");

      this.gtag("config", this.key, {
      "page_title": name,
      "page_path": name,
      "page_location": window.location.href
      });

      const that = this;

      document.addEventListener("click", function(e) {
        if (e.target.classList.contains("track")) {
            that.gtag("event", "click", {
            "event_category": e.target.textContent || e.target.innerText || "-",
            "event_label": e.target.href.pathname
          });
        }
      });
    }
  }

  gtag() {
    this.dataLayer.push(arguments);
  }
}
